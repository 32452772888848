<template>
    <div class="event-blurb column" @click="updateCurrentTimeLineEvent"
    :class="{'current-blurb': isCurrentTimeLineEvent}"> 
        <span>{{event.date}}</span>
        <p>{{event.title}}</p>
    </div>
</template>
<script>
export default {
    name: 'TimeLineEventBlurb', 
    props:{
        event: Object,
    }, 
    computed:{ 
        isCurrentTimeLineEvent(){
            return this.$store.state.currentTimeLineEvent == this.event
        }, 
    }, 
    methods:{
        updateCurrentTimeLineEvent(){
            this.$store.commit('updateCurrentTimeLineEvent', this.event)
            this.$store.commit('updateIsTimeLineEventCardDisplayedStatus', true)
            this.$emit('eventChanged', this.event)
        }
    }

}
</script>
<style lang="less" scoped>

.current-blurb{
    background-color: #3A4DFD !important;
}
.event-blurb{
    cursor: pointer;
    position: relative;
    min-width: 200px;
    min-height: 200px;
    max-width: 200px;
    max-height: 200px;
    background-color: #0A1554;
    border-radius: 50%;
    box-shadow: 0 4px 20px 0 rgba(0,0,0,0.3);
    color: #fff;
    align-content: center;
    justify-content: center;
    
    span{
        max-width: 155px;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.25px;
        line-height: 20px;
        text-align: center;
        text-overflow:ellipsis;
        white-space:normal;
        margin: 0 auto;
        max-height: 60px;

    }
    p{
        margin: 8px auto;
        max-height: 90px;
        max-width: 155px;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.4px;
        line-height: 18px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space:normal;
        color: #fff !important;
    }

 }

 .event-blurb:first-child{
     margin-left: 36px;
 }

 .event-blurb:nth-child(even){
     margin-top: 100px;
 }

</style>


