var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "event-blurb column",
      class: { "current-blurb": _vm.isCurrentTimeLineEvent },
      on: { click: _vm.updateCurrentTimeLineEvent }
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.event.date))]),
      _c("p", [_vm._v(_vm._s(_vm.event.title))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }