var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.timeline && _vm.events.length > 0
    ? _c(
        "div",
        { staticClass: "time-line column" },
        [
          _c("div", {
            staticClass: "timeline-background",
            style: { backgroundColor: _vm.timelineBackGround }
          }),
          _c("span", [
            _c("img", {
              attrs: { src: _vm.infoIcon },
              on: { click: _vm.showTeacherPage }
            }),
            _vm._v("\n    " + _vm._s(_vm.$t("timeline")))
          ]),
          _c(
            "div",
            {
              staticClass: "column",
              class: { "time-line-hidden": _vm.isTimeLineEventCardDisplayed }
            },
            [
              _c(
                "div",
                {
                  key: _vm.timelineBackGround,
                  ref: "events",
                  staticClass: "events-container dFlex custom-scrollbar",
                  style: { color: _vm.timelineBackGround }
                },
                _vm._l(_vm.events, function(event) {
                  return _c("event-blurb", {
                    key: event.id,
                    attrs: { event: event },
                    on: { eventChanged: _vm.selectedEventChanged }
                  })
                })
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "column",
              style: { display: _vm.isTimeLineEventCardDisplayed ? "" : "none" }
            },
            [
              _c("event-card"),
              _c("div", { staticClass: "space__between" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn round primary back",
                    on: { click: _vm.hideCard }
                  },
                  [_vm._v("\n      " + _vm._s(_vm.$t("back")) + "\n    ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn round primary back mr-5",
                    staticStyle: { display: "none" }
                  },
                  [_vm._v("\n      " + _vm._s(_vm.$t("next")) + "\n    ")]
                )
              ])
            ],
            1
          ),
          _c("vue-slider", {
            ref: "slider",
            staticClass: "slider",
            attrs: {
              disabled: _vm.isTimeLineEventCardDisplayed,
              marks: _vm.sliderMarks,
              data: _vm.sliderPoints,
              tooltip: "none",
              min: 0,
              max: _vm.maxOrder,
              "process-style": { backgroundColor: " #11B800", height: "4px" }
            },
            on: {
              dragging: _vm.sliderPositionChanged,
              change: _vm.sliderPositionChanged
            },
            model: {
              value: _vm.sliderPosition,
              callback: function($$v) {
                _vm.sliderPosition = $$v
              },
              expression: "sliderPosition"
            }
          }),
          !_vm.isTimeLineEventCardDisplayed
            ? _c(
                "div",
                { staticClass: "timeline-directions col-12 ml-5 row" },
                [
                  _c("div", { staticClass: "col-6 d-flex" }, [
                    _c("p", [_vm._v(_vm._s(_vm.$t("timelineDirections")))])
                  ]),
                  _c("div", { staticClass: "d-flex j-end pb-10px col-6" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn round primary back back-btn__mr",
                        on: {
                          click: function($event) {
                            _vm.$router.go(-1)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.$t("back")) + "\n      "
                        )
                      ]
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    : _c("span", { staticClass: "centered" }, [
        _vm._v(_vm._s(_vm.$t("noContentToDisplayMessage")))
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }