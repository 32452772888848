var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.event
    ? _c("div", { staticClass: "event-card row container-fluid col-12" }, [
        _c(
          "div",
          {
            ref: "timelineimage",
            staticClass: "col-6 d-flex",
            staticStyle: { height: "fit-content" }
          },
          [
            _c("img", {
              staticClass: "timeline-eventcard__image drop-shadow",
              attrs: { src: _vm.eventImage }
            })
          ]
        ),
        _c("div", { staticClass: "card-body column col-6" }, [
          _c(
            "div",
            { staticClass: "card-header row" },
            [
              _c(
                "div",
                {
                  ref: "cardHeader",
                  staticClass: "column",
                  class: [{ "title-with-audio": _vm.event.audio != null }]
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.event.date))]),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.event.title || "",
                          expression: "event.title || ''"
                        }
                      ]
                    },
                    [_vm._v(_vm._s(_vm.event.title))]
                  )
                ]
              ),
              _vm.event.audio && _vm.isTimeLineEventCardDisplayed
                ? _c("AudioToggle", {
                    key: _vm.event.id,
                    attrs: { "white-background": true, audio: _vm.event.audio }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "body",
              style: {
                "max-height": _vm.maxHeightContent + "px",
                padding: "10px"
              }
            },
            [
              _c("div", { staticClass: "card-overflow h-100" }, [
                _c("span", {
                  staticClass: "custom-scrollbar timline-text",
                  domProps: { innerHTML: _vm._s(_vm.event.longDescription) }
                })
              ])
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }