<template>
  <div class="event-card row container-fluid col-12" v-if="event">
    <div style="height: fit-content" class="col-6 d-flex" ref="timelineimage">
      <img :src="eventImage" class="timeline-eventcard__image drop-shadow" />
    </div>
    <div class="card-body column col-6">
      <div class="card-header row">
        <div
          class="column"
          ref="cardHeader"
          :class="[{ 'title-with-audio': event.audio != null }]"
        >
          <span>{{ event.date }}</span>
          <span v-tooltip="event.title || ''">{{ event.title }}</span>
        </div>
        <AudioToggle
          v-if="event.audio && isTimeLineEventCardDisplayed"
          :white-background="true"
          :key="event.id"
          :audio="event.audio"
        />
      </div>
      <div class="body" :style="{ 'max-height': maxHeightContent +'px', 'padding': '10px' }">
        <div class="card-overflow h-100">
          <span class="custom-scrollbar timline-text" v-html="event.longDescription"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AudioToggle from "@/common/AudioToggle.vue";
export default {
  name: "TimeLineEventCard",
  components: {
    AudioToggle,
  },
  data(){
    return{
      maxheight: '',
      maxHeightContent:''
    }
  },
  computed: {
    event() {
      return this.$store.state.currentTimeLineEvent;
    },
    eventImage() {
      return this.getImageUrl(this.event.imageName);
    },
    isTimeLineEventCardDisplayed() {
      return this.$store.state.isTimeLineEventCardDisplayed;
    },
  },
  methods: {
    getTimelineContentHeight() {
      var fullHeight = this.$refs.timelineimage.clientHeight;
      var headerTitleHeight = this.$refs.cardHeader.clientHeight;
      const shadowHeight = 10;
      this.maxheight = fullHeight;
      this.maxHeightContent = fullHeight - headerTitleHeight - shadowHeight;
    },
  },
  mounted() {},
  updated() {
    setTimeout(()=>{
           this.getTimelineContentHeight();
      },1000)
  },
};
</script>
<style lang="less">
.event-card {
  width: 90%;
  display: flex;
  margin-inline: auto;
  flex-wrap: nowrap;
  margin-top: 47px;
  img {
    // max-height: 600px;
    max-width: 600px;
    height: auto;
    margin-left: auto;
  }
  .card-body {
    height: auto;
    // width: 520px;
    margin-left: 32px;
    margin-right: auto;
    background-color: #ffffff;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
    .card-header {
      min-height: 87px;
    //   width: 100%;
      background-color: #0a1554;
      padding: 11px 24px;
      box-sizing: border-box;
      > div {
        width: 90%;
        max-width: 90%;
        span {
          margin-top: 8px;
          color: #ffffff;
          font-size: 19.64px;
          font-weight: bold;
          letter-spacing: 0.25px;
          line-height: 24px;
          text-align: center;
        }
        span:nth-child(2) {
          font-size: 23.92px;
          font-weight: 500;
          line-height: 29px;
          text-align: center;
          white-space: normal;
          height: auto;
          max-height: 90px;
        }
      }
      .title-with-audio {
        // width: 90%;
        // max-width: 90%;
        max-height: 120px;
      }
      .audio-toggle {
        margin-top: 20px;
      }
    }
    > p {
      margin-block-start: unset;
      margin-block-end: unset;
      height: auto;
      // width: 90%;
      // max-width: 90%;
      color: #000;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      letter-spacing: 0.5px;
      line-height: 24px;
      padding: 16px 24px;
      p:nth-child(1) {
        margin-block-start: unset;
        margin-block-end: unset;
      }
    }
  }
  transition: opacity 2s;
}
.card-overflow {
  overflow: auto;
}
.timline-text{
    font-size: 18px;
}
</style>